import { Route, Switch } from 'react-router-dom';
import React, { createContext } from 'react';

import './App.css';
import routeConfig from './routeConfig';
import routing from './utils/routing';

const AppContext = createContext(null);

const App = () => {
  return (
    <div className="App">
      <Switch>
        {routeConfig.map((config, index) => (
          <Route
            key={index}
            path={config.route}
            exact={config.components.some(component => component.props && component.props.exact)}
          >
            {routing.renderNestedComponents(config)}
          </Route>
        ))}
      </Switch>
    </div>
  );
}

export { AppContext };
export default App;

import Home from "./pages/home/Home";

const routeConfig = [{
  route: "/",
  components: [
    Home
  ]
}]

export default routeConfig;
